import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction-to-tyro-eftpos--healthpoint",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction-to-tyro-eftpos--healthpoint",
        "aria-label": "introduction to tyro eftpos  healthpoint permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction to Tyro EFTPOS & HealthPoint`}</h1>
    <p>{`Tyro EFTPOS machines, also called terminals, can be integrated via the Medipass / Tyro Health platform enabling the following functionality:`}</p>
    <ul>
      <li parentName="ul">{`Send payment requests from your application to a Tyro EFTPOS machine for cardholder approval and integrated reconciliation, and`}</li>
      <li parentName="ul">{`Send Tyro HealthPoint requests, for private health insurance claims and rebate estimates, from your application to a HealthPoint enabled Tyro EFTPOS machine.`}</li>
    </ul>
    <p>{`These functions are accessed via the Tyro Health Transaction SDK which is a Javascript library.`}</p>
    <p>{`The SDK supports:`}</p>
    <ul>
      <li parentName="ul">{`Connectivity to terminals without dedicated cables, physical adapters or Bluetooth pairing`}</li>
      <li parentName="ul">{`Multiple workstations and users can access a single terminal`}</li>
      <li parentName="ul">{`The SDK can be used with modern web browser experiences or rendered as a web view in native applications`}</li>
    </ul>
    <p>{`The Tyro Health Transaction SDK sits alongside other Tyro integration methods, which include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tyro terminal adapter (TTA)`}</strong>{`: a .net program designed for Windows based POS and PMS applications.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tyro iClient`}</strong>{`: a Javascript library with support for cloud or browser based POS and PMS systems.`}</li>
    </ul>
    <p>{`Tyro HealthPoint enables healthcare providers and merchants to process instant private health insurance and overseas insurance claims with over 34 funders, covering >98% of the insured population, using Tyro CounterTop EFTPOS machines.`}</p>
    <p>{`Tyro also accepts all major debit and credit card types, including Visa, Mastercard, eftpos, American Express, Diners Club, JCB, Alipay and UnionPay through CounterTop EFTPOS machines.`}</p>
    <p>{`The Tyro Health Transaction SDK integrates with Tyro EFTPOS machines along with card-not-present solutions. It facilitates healthcare providers to send Tyro HealthPoint payment requests for private health insurance claims, rebate estimates process gaps and other payments utilising the same integration SDK that supports card-not-present (SMS) payment requests and claims with other funders such as DVA, icare, Worksafe Vic, Workcover QLD, Comcare and more.`}</p>
    <p>{`Payment and claim processing is presented via a self-contained user interface which will validate values and prompt for correction on error. This UI can be exposed as either a lightbox modal or an embedded frame.`}</p>
    <p>{`To simplify ongoing support for your providers, we include a real-time chat and support capability. The chat links to our help system which identifies the business and status of transactions. This means that claim and payment transaction issues and inquiries can be easily directed to us at time of transaction rather than your help desk.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      